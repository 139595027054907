<template>
  <section id="pricing-plan">
    <div class="text-center">
      <h1 class="mt-2">
        {{ $t('Licenças') }}
      </h1>
      <p class="mb-2 pb-75">
        {{ $t('Escolha a melhor licença para as suas necessidades') }}
      </p>
    </div>

    <!-- pricing plan cards -->
    <b-row class="pricing-card">
      <b-col offset-sm-2 sm="10" md="12" offset-lg="2" lg="10" class="mx-auto"
        style="display: grid;grid-template-columns: 1fr 1fr; gap: 22px;">
        <b-row style="display: flex; flex-flow: column;">
          <PlanCard v-for="(plan, index) in plans" :key="index" :plan="plan" :valueProgress="60"
            :isSubscribed="checkSubscription(plan)" />
        </b-row>
        <b-row style="display: flex; flex-flow: column;">
          <PlanCard v-for="(plan, index) in plans" :key="index" :isHighRisk="true" :plan="plan" :valueProgress="60"
            :isSubscribed="checkSubscription(plan)" />
        </b-row>
      </b-col>
    </b-row>
    <!--/ pricing plan cards -->
  </section>
</template>

<script>
import {
  BFormCheckbox, BRow, BCol, BCard, BImg, BCardText, BListGroup, BListGroupItem, BButton, BBadge,
} from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import Ripple from 'vue-ripple-directive'
import PlanCard from "./PlanCard.vue"
/* eslint-disable global-require */
export default {
  components: {
    BFormCheckbox,
    BButton,
    BCardText,
    BListGroup,
    BListGroupItem,
    BRow,
    BCol,
    BCard,
    BBadge,
    BImg,
    AppCollapseItem,
    AppCollapse,
    PlanCard
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      status: 'monthly',
      monthlyPlanShow: true,
      user: {
        subscribedPlans: ['Aureate Grower', 'Bullion Builder']
      },
      plans: [
        {
          img: require('@/assets/images/business_plan/start.png'),
          value: 10,
        },
        {
          img: require('@/assets/images/business_plan/start.png'),
          value: 20,
        },
        {
          img: require('@/assets/images/business_plan/start.png'),
          value: 30,
        },
        {
          img: require('@/assets/images/business_plan/start.png'),
          value: 50,
        },
        {
          img: require('@/assets/images/business_plan/start.png'),
          value: 100,
        },
        {
          img: require('@/assets/images/business_plan/start.png'),
          value: 200,
        },
        {
          img: require('@/assets/images/business_plan/start.png'),
          value: 300,
        },
        {
          img: require('@/assets/images/business_plan/start.png'),
          value: 500,
        },
        {
          img: require('@/assets/images/business_plan/pro.png'),
          value: 1000,
        },
        {
          img: require('@/assets/images/business_plan/pro.png'),
          value: 2000,
        },
        {
          img: require('@/assets/images/business_plan/pro.png'),
          value: 5000,
        },
        {
          img: require('@/assets/images/business_plan/premium.png'),
          value: 10000,
        },
        {
          img: require('@/assets/images/business_plan/premium.png'),
          value: 20000,
        },
        {
          img: require('@/assets/images/business_plan/premium.png'),
          value: 50000,
        },
        {
          img: require('@/assets/images/business_plan/premium.png'),
          value: 100000,
        },
      ],
      qandA: [
        {
          question: 'Os planos de investimento renovam automaticamente?',
          ans:
            'Não, nossos planos de investimento não renovam automaticamente. No final do seu período de investimento, você será notificado e terá a opção de renovar ou escolher um plano diferente.',
        },
        {
          question: 'Os planos de investimento renovam automaticamente?',
          ans:
            'Não, nossos planos de investimento não renovam automaticamente. No final do seu período de investimento, você será notificado e terá a opção de renovar ou escolher um plano diferente.',
        },
        {
          question: 'Os planos de investimento renovam automaticamente?',
          ans:
            'Não, nossos planos de investimento não renovam automaticamente. No final do seu período de investimento, você será notificado e terá a opção de renovar ou escolher um plano diferente.',
        },
      ]
    }
  },
  methods: {
    checkSubscription(plan) {
      // Aqui, assumo que você tenha um objeto 'user' que contém uma lista de planos nos quais o usuário está inscrito.
      return this.user.subscribedPlans.includes(plan.title);
    },
    tooglePlan() {
      if (this.status === 'monthly') {
        this.monthlyPlanShow = true
      } else {
        this.monthlyPlanShow = false
      }
    },
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-pricing.scss';

.pricing-faq {
  margin-top: 2rem;
}
</style>
