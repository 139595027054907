<template>
    <b-col>
        <b-card align="center">
            <div v-if="isHighRisk" class="high-risk-badge">
                {{ $t('Alto Risco') }}
            </div>

            <b-img v-if="plan.img" :src="plan.img" class="mb-1 plan-image" alt="svg img" />
            <h5>{{ $t('Com esta licença você pode alocar para operação até') }} {{ moneyFormat(plan.value * 3) }}</h5>
            <div class="annual-plan">
                <div class="plan-price mt-2">
                    <span class="pricing-basic-value font-weight-bolder text-primary">{{ plan.value | formatNumber
                    }}</span>
                    <sup class="font-medium-1 font-weight-bold text-primary"> USDT</sup>
                </div>
            </div>
            <!-- <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" block class="mt-2" variant="primary"
            @click="() => signPlan(plan.value)" :disabled="btnLoading">
            {{ btnLoading ? $t('Aguarde...') : $t('Comprar') }}
        </b-button> -->
            <div class="mt-2">
                <div class="mb-1" style="color: greenyellow;font-size: 12pt;"
                    :style="{ visibility: isHighRisk ? 'visible' : 'hidden' }">
                    {{ $t('Ganhe até 1% ao dia') }}
                </div>
                <b-button block class="buy-button" variant="primary"
                    @click="() => openModalBuyPlan(`${plan.product}_${plan.value}${isHighRisk ? '_high' : ''}`)"
                    :disabled="btnLoading">
                    {{ btnLoading ? $t('Aguarde...') : $t('Comprar') }}
                </b-button>
            </div>
        </b-card>

        <b-modal :id="`modal-buy-plan-${`${plan.product}_${plan.value}${isHighRisk ? '_high' : ''}`}`"
            :title="$t('Adquirir licença')" centered hide-footer no-close-on-backdrop>
            <div v-if="loadingPayment" class="text-center">
                {{ $t("Aguarde! Carregando...") }}
            </div>
            <div v-else>
                <div v-if="paymentDetails && paymentDetails.searchPendingPay">
                    <!-- Exibe detalhes do pagamento pendente -->
                    <div class="text-center mb-1">
                        <h5>{{ $t("Tempo restante para pagamento:") }} <span class="text-danger">{{ countdown }}</span>
                        </h5>
                    </div>

                    <div class="text-center">
                        <h5>{{ $t("Valor a ser transferido:") }} <span class="text-primary">{{
                            paymentDetails.searchPendingPay.value }} USDT</span></h5>
                    </div>
                    <div class="text-center text-warning"><small>{{ $t('1 USDT + 4% de Fee') }}</small></div>

                    <div class="text-center my-2">
                        <h5>{{ $t("Rede:") }} <span class="badge badge-warning">Binance Smart Chain (BSC)</span></h5>
                    </div>

                    <div class="text-center my-1">
                        <h5 class="mb-0">{{ $t("Endereço de depósito:") }}</h5>
                        <div class="d-flex justify-content-center align-items-center">
                            <span :class="{ 'text-success': addressCopied, 'text-primary': !addressCopied }">
                                {{ addressCopied ? $t("Endereço copiado") :
                                    compactAddress(paymentDetails.depositAddress) }}
                            </span>
                            <b-button variant="link" @click="copyToClipboard(paymentDetails.depositAddress)"
                                class="ml-1">
                                <i class="fas fa-copy"></i> {{ $t('Copiar') }}
                            </b-button>
                        </div>
                    </div>


                    <!-- Exibe o QR Code -->
                    <div v-if="qrCodeData" class="text-center my-1">
                        <h5>{{ $t("Escaneie o QR Code para abrir o endereço de depósito na sua carteira") }}:</h5>
                        <img :src="qrCodeData" alt="QR Code" class="qr-code" />
                    </div>

                    <!-- Mensagem de Observação -->
                    <div class="text-center mt-3">
                        <p class="text-warning">{{ $t("Se você já fez o pagamento, aguarde a validação do sistema.") }}
                        </p>
                        <p class="text-warning">{{ $t("Aguarde 1 minuto...") }}</p>
                    </div>
                </div>
                <div v-else>
                    <!-- Exibe a opção de gerar um novo endereço para depósito -->
                    <div class="text-center">
                        <h5 class="text-center">
                            {{ $t("Você está adquirindo um plano") }}
                            <span class="text-primary">{{ plan.product }}</span>
                        </h5>
                        <h5 class="text-center">
                            {{ $t("no valor de") }}
                            <span class="text-primary">{{ moneyFormat(plan.value) }}</span>
                        </h5>
                        <div class="d-flex justify-content-center mt-2">
                            <b-button variant="primary" @click="createDepositIntent" :disabled="btnLoading">
                                {{ btnLoading ? $t("Aguarde...") : $t("Gerar endereço para depósito") }}
                            </b-button>
                        </div>
                    </div>
                </div>
            </div>
        </b-modal>
    </b-col>
</template>

<script>
import { BProgress } from 'bootstrap-vue'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import QRCode from "qrcode";

export default {
    components: {
        BProgress,
    },
    data() {
        return {
            addressCopied: false,
            btnLoading: false,
            loadingPayment: false,
            paymentDetails: {
                searchPendingPay: {
                    value: 0,
                    crypto: '',
                    createdAt: ''
                },
                depositAddress: ''
            },
            countdown: "00:00:00",
            qrCodeData: null,
        }
    },
    props: {
        plan: {
            type: Object,
            required: true,
        },
        isSubscribed: {
            type: Boolean,
            default: false,
        },
        valueProgress: Number,
        isHighRisk: {
            type: Boolean,
            default: false,
        },
    },
    filters: {
        formatNumber: function (value) {
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
    },
    methods: {
        compactAddress(address) {
            return address ? `${address.slice(0, 10)}...${address.slice(-10)}` : '';
        },
        copyToClipboard(address) {
            navigator.clipboard.writeText(address).then(() => {
                this.addressCopied = true;
                setTimeout(() => {
                    this.addressCopied = false;
                }, 3000); // Após 3 segundos, retorna o endereço
                this.$bvToast.toast(this.$t('Endereço copiado!'), {
                    title: this.$t('Sucesso'),
                    variant: 'success',
                    solid: true,
                });
            }).catch(err => {
                // console.error('Erro ao copiar endereço:', err);
            });
        },
        openModalBuyPlan(planID) {
            this.$bvModal.show(`modal-buy-plan-${planID}`);
            this.getDepositIntent();
        },
        getDepositIntent() {
            this.loadingPayment = true;
            const data = {
                value: this.plan.value,
                product: 'license',
            };
            this.$store
                .dispatch("getDepositIntent", data)
                .then((resp) => {
                    this.loadingPayment = false;
                    if (resp && resp.searchPendingPay) {
                        this.paymentDetails = resp;
                        this.startCountdown(this.paymentDetails.searchPendingPay.createdAt); // Inicia a contagem regressiva
                        this.generateQRCode(); // Gera o QR Code
                    } else {
                        // console.error('Dados de pagamento incompletos:', resp);
                    }
                })
                .catch((err) => {
                    this.loadingPayment = false;
                    // console.error('Erro ao buscar detalhes do pagamento:', err);
                    this.paymentDetails = {}; // Certifique-se de limpar dados incorretos
                });
        },
        createDepositIntent() {
            this.btnLoading = true;
            const data = {
                value: this.plan.value,
                product: 'license',
                isHighRisk: this.isHighRisk,
            };
            this.$store
                .dispatch("signPlanRequest", data)
                .then((resp) => {
                    this.btnLoading = false;
                    this.getDepositIntent();
                })
                .catch((err) => {
                    this.btnLoading = false;
                });
        },
        startCountdown(targetDate) {
            // Converte a data de entrada para um objeto Date e adiciona 1 hora
            let target = new Date(targetDate);
            target.setMinutes(target.getMinutes() + 10);

            // Função que atualiza a contagem regressiva
            const updateCountdown = () => {
                const now = new Date().getTime();
                const timeDifference = target.getTime() - now;

                if (timeDifference > 0) {
                    const hours = Math.floor(
                        (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
                    );
                    const minutes = Math.floor(
                        (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
                    );
                    const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

                    this.countdown = `${hours.toString().padStart(2, "0")}:${minutes
                        .toString()
                        .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
                } else {
                    this.countdown = "00:00:00";
                    clearInterval(countdownInterval);
                }
            };

            // Atualiza a cada segundo
            const countdownInterval = setInterval(updateCountdown, 1000);
            updateCountdown();
        },
        generateQRCode() {
            const depositAddress = this.paymentDetails.depositAddress;
            const value = this.paymentDetails.searchPendingPay.value;

            // USDT BEP-20 contract address on BSC
            const usdtContractAddress = '0x55d398326f99059ff775485246999027b3197955';

            // Correct URI for USDT transfer on Binance Smart Chain
            const uri = `ethereum:${depositAddress}`;

            // Generate the QR code using the qrcode library
            QRCode.toDataURL(uri)
                .then((url) => {
                    this.qrCodeData = url; // Save the QR code URL for display
                })
                .catch((err) => {
                    // console.error(err);
                });
        },
        signPlan(value) {
            this.btnLoading = true;
            this.$store
                .dispatch("signPlanRequest", value)
                .then((resp) => {
                    this.btnLoading = false;
                    this.$toast({
                        component: ToastificationContent,
                        position: "top-right",
                        props: {
                            icon: "CheckCircleIcon",
                            variant: "success",
                            title: this.$i18n.t("Parabéns!"),
                            text: this.$i18n.t(resp.message)
                        },
                    });
                }).catch((err) => {
                    this.btnLoading = false;
                    this.$toast({
                        component: ToastificationContent,
                        position: "top-right",
                        props: {
                            icon: "AlertCircleIcon",
                            variant: "danger",
                            title: this.$i18n.t("Erro!"),
                            text: this.$i18n.t(err.message)
                        },
                    });
                })
        }
    }
};
</script>

<style scoped>
.plan-image {
    width: 50%;
    /* substitua pelo tamanho desejado */
    height: 50%;
    /* substitua pelo tamanho desejado */
}

.high-risk-badge {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1;
    background-color: red;
    padding: 4px 8px;
    color: white;
    border-radius: 16px;
}
</style>